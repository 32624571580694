<template>
    <div>

        <!--EYLF Checklist-->
        <vx-card class="mb-base eylf-card">
            <vs-row>
                <vs-col vs-lg="4" vs-sm="12" vs-xs="12" class="eylf-col">
                    <h2>Learning Framework Outcomes</h2>
                    <br>
                    <div v-for="outcome  in eylfChecklistOutcomes" :key="outcome._id">
                        <div class="flex mb-3">
                            <div>
                                <h3 class="h4 mb-3" style="font-weight: 600; line-height: 24px;">{{outcome.eylfChecklists.title}}</h3>
                                <p>{{outcome.eylfChecklists.description}}</p>
                            </div>
                            <div class="mr-8">
                                <p>{{outcome.count}}</p>
                            </div>
                        </div>
                        <br>
                    </div>

                </vs-col>
                <vs-col vs-lg="4" vs-sm="12" vs-xs="12" class="eylf-col">
                    <h2>Learning Framework Principles</h2>
                    <br>
                    <div v-for="outcome  in eylfChecklistPrinciples" :key="outcome._id">
                        <div class="flex mb-3">
                            <div>
                                <h3 class="h4 mb-3" style="font-weight: 600; line-height: 24px;">{{outcome.eylfChecklists.title}}</h3>
                                <p>{{outcome.eylfChecklists.description}}</p>
                            </div>
                            <div class="mr-8">
                                <p>{{outcome.count}}</p>
                            </div>
                        </div>
                        <br>
                    </div>

                </vs-col>
                <vs-col vs-lg="4" vs-sm="12" vs-xs="12" class="eylf-col">
                    <h2>Learning Framework Practices</h2>
                    <br>
                    <div v-for="outcome  in eylfChecklistPractices" :key="outcome._id">
                        <div class="flex mb-3">
                            <div>
                                <h3 class="h4 mb-3" style="font-weight: 600; line-height: 24px;">{{outcome.eylfChecklists.title}}</h3>
                                <p>{{outcome.eylfChecklists.description}}</p>
                            </div>
                            <div class="mr-8">
                                <p>{{outcome.count}}</p>
                            </div>
                        </div>
                        <br>
                    </div>

                </vs-col>

            </vs-row>
        </vx-card>
    </div>
</template>
<script>
    import _ from 'lodash'
    import {mapActions} from "vuex";


    export default {
        data() {
            return {
                eylfChecklist: [],
                eylfChecklistPractices: [],
                eylfChecklistPrinciples: [],
                eylfChecklistOutcomes: [],
                teacher:{},

            }
        },
        methods: {
            ...mapActions("teacher", [
                "getEylfsWithLearningStory"
            ]),
            async getEylfChecklist(centerId) {
                this.getEylfsWithLearningStory(centerId).then(response => {
                    this.eylfChecklist = response.data.data;

                    this.eylfChecklistOutcomes = _.filter(this.eylfChecklist, function(item){
                        return item.eylfChecklists.type === 'outcomes';
                    });

                    this.eylfChecklistPrinciples = _.filter(this.eylfChecklist, function(item){
                        return item.eylfChecklists.type === 'principles';
                    });

                    this.eylfChecklistPractices = _.filter(this.eylfChecklist, function(item){
                        return item.eylfChecklists.type === 'practices';
                    });

                })
            },

        },
        created() {
           //
            this.teacher = this.$store.state.AppActiveUser;
            this.getEylfChecklist(this.teacher.learningCenterId);
        }

    }

</script>

<style lang="scss">
    table {
        border-collapse: collapse;
    }

    table tr td {
        border-bottom: 1px solid #d8d8d8;
        border-left: 1px solid #d8d8d8;
        padding: 10px;
    }

    table tr td:first-child {
        border-left: none;
    }

    .vs-tooltip {
        z-index: 100000;
    }

    .demo-alignment {
    img {
        margin-top: 0 !important;
    }
    }

    .professional-development-table {
    th {
    &:first-child {
         min-width: 120px;
         width: 120px;
    @media (min-width: 1200px){
        min-width: 160px;
        width: 160px;
    }
    @media (min-width: 1480px){
        min-width: 200px;
        width: 200px;
    }
    }
    &:nth-child(2){
         min-width: 120px;
         width: 120px;
    @media (min-width: 1200px){
        min-width: 250px;
        width: 250px;
    }
    @media (min-width: 1480px){
        min-width: 300px;
        width: 300px;
    }
    }
    &:last-child {
         min-width: 500px;
         width: 500px;
    @media (min-width: 1200px){
        min-width: 700px;
        width: 700px;
    }
    @media (min-width: 1480px){
        min-width: 800px;
        width: 800px;
    }
    }
    }
    .vs-con-textarea {
        margin-bottom: 0;
    }
    .vs-textarea {
        min-height: 60px;
        height: 60px;
    }
    .vs-button {
        padding: 10px !important;
    }
    }
    .vs-popup {
    @media (min-width: 1480px){
        width: 800px !important;
    }
    .vs-popup--content {
        padding: 30px;
    }
    .vs-popup--header {
        height: 0;
    .vs-popup--title {
    h3 {
        display: none;
    }
    }
    }
    .vs-popup--close {
        transform: none !important;
        background: none !important;
        box-shadow: none;
        position: relative;
        top: 35px;
        right: 20px;
        font-size: 25px;
        color: #2B2D3D;
    &:hover {
         color: #2d9ad5;
     }
    }
    }

    .range {
        position: relative;
        width: 100%;
        height: 5px;
    input[type='radio'] {
        border: 13.5px solid #2898D5;
        border-radius: 50%;
        width: 27px;
        position: absolute;
        height: 27px;
        -webkit-appearance: none;
        top: -12px;
        right: -12px;
        transition: 0.4s all ease-in-out;
        z-index: 1;
        opacity: 0;
    &:checked {
         opacity: 1;
     }
    }
    .range-labels {
        background: #f0f0f0;
        height: 6px;
        border-radius: 3px;
        padding: 0;
        list-style: none;
    li {
        display: inline-block;
        position: relative;
        width: calc(100% / 5);
        text-align: left;
        color: black;
        font-size: 14px;
        height: 6px;
        top: -9px;
        cursor: pointer;
    &:first-child {
         width: 0;
     }
    &::before {
         content: "";
         position: absolute;
         top: -4px;
         right: 0;
         width: 3px;
         height: 13px;
         background: #606060;
     }
    label {
        position: absolute;
        top: 20px;
        right: -2px;
    }
    }
    }
    }

    .tag {
        width: 21px;
        height: 21px;
        border-radius: 50%;
        display: inline-block;
        background: #2898D5;
        position: relative;
        top: 5px;
        margin: 0 5px;
    }

    .vs-tooltip {
        color: #222222;
        font-size: 15px;
        line-height: 20px;
        padding: 15px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
        min-width: 300px !important;
    }
</style>
